<template>
  <div id="nav" class="relative z-30">
    <div class="w-screen overflow-hidden relative z-40 px-8">
      <div class="container py-4 lg:py-8 relative flex justify-between items-center ">
          <div class="relative mb-0">
            <slot name="brand" />
          </div>
          <div class="h-6">
            <a href="/get-a-quote" class="submit-btn gaq hidden sm:inline-block font-axia inline-block mr-8 font-black">Get A Quote</a>
            <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;addo();addh();" class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isActive }"  class="absolute overflow-hidden z-30 main-show w-screen block h-screen bg-secondary top-0 right-0 left-0 bottom-0 invisi">
      <img src="/app/themes/wordpress/assets/images/menu-shape-1.svg" class="absolute right-6 bottom-16 lg:bottom-10" alt="Menu Shapes">
      <img src="/app/themes/wordpress/assets/images/menu-rips-left.svg" class="absolute left-0 top-0" alt="Menu Shapes">
      <img src="/app/themes/wordpress/assets/images/meni-rips-right.svg" class="absolute right-0 bottom-0" alt="Menu Shapes">
      <img src="/app/themes/wordpress/assets/images/menu-shape-2.svg" class="absolute hidden lg:block -left-20" alt="Menu Shapes" style="top:35%;">
      <img src="/app/themes/wordpress/assets/images/menu-text.svg" class="hidden lg:block absolute right-10 w-40 bottom-8" alt="Menu Shapes">
      <div class="relative h-screen w-screen flex items-center overflow-y-scroll justify-center block">
        <div>
          <slot name="mainmenu" />
          <img src="/app/themes/wordpress/assets/images/menu-text-2.svg" class="lg:hidden mt-12 mx-auto" alt="Menu Shapes">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    addom () {
      document.querySelector('.desktopmenu').classList.toggle('offscreen')
    },
    addomt () {
      document.querySelector('.moverin').classList.toggle('onscreen')
    },
    addh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
    removeo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.moverin{
  right: -101vw;
}
.onscreen{
  right: 0!important;
}
.offscreen .mover{
  left: -101vw!important;
}
.invisi{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-left: -200vw;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-left: 0;
}
.slideit{
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-left: -105vw;
  }
  30% {
    margin-left: 0;
  }
  70%{
    margin-left: 0;
  }
  100% {
    margin-left: 105vw;
  }
}
</style>
